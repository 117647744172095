nav {
    background-color: #382A1E;
    font-size: 16px;
}

nav a {
    color: #F8F4EF;
    text-decoration: none;
    white-space: nowrap;
}

nav a:hover {
    color: #ffffff;
    background-color: hsl(28, 30%, 25%);
}

.highlighted {
    color: #ffffff;
    background-color: hsl(28, 30%, 25%);
}

nav button {
    padding: 10px;
    color: #F8F4EF;
    background-color: #382A1E;
    border: none;
    font-size: 16px;
}

nav button:hover {
    color: #ffffff;
    background-color: hsl(28, 30%, 25%);
    cursor: pointer;
}

nav .menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

nav .menu * {
    flex: 1;
    padding: 10px;
}

nav .overflowMenu {
    display: flex;
    flex-flow: column;
}

nav .overflowMenu * {
    padding: 10px;
}