.modal-container {
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.modal {
    padding: 1rem;
    background: linear-gradient(147deg, rgba(174,138,105,1) 0%, rgba(149,112,79,1) 16%, rgba(74,56,40,1) 100%);
    color: white;
    /* border-radius: 5px; */
    /* border: 1px solid rgba(255, 255, 255, 0.164); */
    margin: 1rem;
    display: flex;
    flex-direction: column;
    min-width: 30%;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

@media only screen and (max-width: 600px) {
    .modal {
        min-width: 80%;
    }
}

.modal .close-button {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1rem;
    margin-left: auto;
}

.activator {
    width: fit-content;
}
