.weekList {
    text-align: right;
}

.title {
    padding: 10px;
    margin-bottom: 20px;

    color: #F8F4EF;
    background-color: #5D4632;
    text-align: center;
    font-size: large;
}

.weekListEditButtonContainer {
    background-color: rgb(217, 210, 191);
}

.weekListEditButton {
    width: 20%;
    height: 30px;
}

.weekListSortButton {
    width: 35%;
    height: 30px;
}

.topSection {
    display: grid;
    grid-template-columns: 55px 1fr;
}

.topSection .day, .topSection .meal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px;
}

.topSection .day {
    background-color: rgb(217, 210, 191);
    text-align: center;
}

.topSection .day.today {
    background-color: rgb(161, 181, 161);
}

.topSection .meal {
    background-color: hsl(56, 25%, 87%);
    text-align: left;
}

.topSection .meal.today {
    background-color: rgb(175, 197, 175);
}

.topSection .editModeMeals {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    background-color: hsl(56, 25%, 87%);
}

.topSection .editModeDays {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
}

.topSection .mealEditMode {
    display: grid;
    grid-template-columns: auto 1fr;
}

.sortButtonContainer {
    text-align: right;
}

.sortButton {
    /* border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit; */

    margin-bottom: 5px;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;

    /* flex-grow: 1;
    background: hsl(40, 24%, 65%);
    background: linear-gradient(147deg, hsl(40, 34%, 60%) 16%, hsl(40, 34%, 45%) 100%); 

    color: #F8F4EF; */
}

/* .sortButton:hover {
    background: hsl(40, 24%, 65%);
    background: linear-gradient(147deg, hsl(40, 34%, 70%) 16%, hsl(40, 34%, 60%) 100%); 
} */

.bottomSection {
    text-align: left;
    padding: 10px;
    background-color: rgb(230, 229, 213);
    border-top: 4px dotted hsl(37, 45%, 35%);
}

.bottomSection p {
    margin-top: 0;
    margin-bottom: 0;
}

.shoppingList {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(10, auto);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 2%;
}

.shoppingItemViewMode, .shoppingItemEditMode {
    display: grid;
    align-items: center;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.267);
}

.shoppingItemViewMode {
    grid-template-columns: auto auto auto minmax(0, 1fr);
    grid-gap: .3rem;
    padding: 0.15em;
    /* padding-left: 0.5em; */
    padding-right: 0.5em;
}

.shoppingItemEditMode {
    grid-template-columns: auto 1fr auto;
}

.shoppingListItemMenuButton {
    width: 38px;
    height: 38px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    margin-right: 5px;
    font-size: larger;
    background-color: #e9e9ed;
}

.shoppingListItemMenuButton:hover {
    background-color: #d0d0d7;
}

.shoppingListItemInlineNote {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(106, 108, 121);
    font-style: italic;;
}

.shoppingListItemDeleteButton {
    background-color: #5D4632;
    color: #ff6e6e;
    border: none;
    font-size: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
}

.shoppingListItemDeleteButton:hover {
    background-color: hsl(28, 30%, 32%);
    color: #ff9c9c;
}

.shoppingListEditModeModal {
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,.082);
    padding: 1rem;
    /* min-width: 25rem; */
}

.shoppingItemEditMode input[type="text"] {
    box-sizing: border-box;
    width: 100%;
}

.dragHandleContainer {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.dragHandleContainer > .dragHandle {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsLW9wYWNpdHk9IjAiPjwvcmVjdD4KPHJlY3Qgd2lkdGg9IjIiIGhlaWdodD0iMiIgZmlsbD0iIzAwMCI+PC9yZWN0Pgo8L3N2Zz4=");
    width: 12px;
    height: 76%;
    cursor: grab;
}

.sortable-chosen {
    color: green;
}

.addNewButton {
    /* width: 50%; */
    margin-top: 3px;
    margin-bottom: 3px;
    height: 38px;
    width: 100%;
}

/* Phones sizes */
@media only screen and (max-width: 1900px) {
    .shoppingList {
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
}

@media only screen and (max-width: 1200px) {
    .shoppingList {
        grid-auto-flow: unset;
        grid-template-columns: minmax(0, 1fr);
    }

    .shoppingItemViewMode input[type=checkbox] {
        height: 25px;
        width: 25px;
        background-color: #5D4632;
    }
}

@media only screen and (max-width: 600px) {
    .shoppingList {
        grid-auto-flow: unset;
        grid-template-columns: minmax(0, 1fr);
    }

    .add-new-button {
        width: 100%;
        height: 50px;
    }
}
