.confirmDeleteButton {
  display: inline-block;

  margin-top: 5px;
  margin-bottom: 5px;
}

.confirmDeleteButton > button {
  padding: 10px;
  color: #F8F4EF;
  background-color: hsl(28, 30%, 17%);
  border: none;
  font-size: 16px;

  margin-left: 0.2em;
  margin-right: 0.2em;
}

.confirmDeleteButton > button:hover {
  color: #ffffff;
  background-color: hsl(28, 30%, 25%);
  cursor: pointer;
}

.confirmDeleteButton > button:disabled {
  background-color: hsl(0, 0%, 26.7%);
}


.confirmDeleteButton > .deleteButton {
  padding: 10px;
  color: #F8F4EF;
  background-color: hsl(0, 100%, 24%);
  border: none;
  font-size: 16px;
}

.confirmDeleteButton > .deleteButton:hover {
  color: #ffffff;
  background-color: hsl(0, 100%, 32%);
  cursor: pointer;
}