.WeekListList {
    display: flex;
    flex-flow: column wrap;
}

.weekListItemContainer {
    display: flex;
    flex-flow: row nowrap;
}

.weekListItem {
    margin: 5px;
    padding: 10px;
    flex-grow: 1;

    background-color: #5D4632;
    text-decoration: none;
    color: #F8F4EF;
}

.weekListItem:hover {
    background-color: hsl(28, 30%, 32%);
    color: #F8F4EF;
}

.newWeekButton {
    margin: 5px;
    padding: 10px;

    flex-grow: 1;
    background: rgb(90,149,79);
    background: linear-gradient(147deg, rgba(90,149,79,1) 16%, rgba(57,105,56,1) 100%); 

    text-decoration: none;
    color: #F8F4EF;
}

.newWeekButton:hover {
    background: rgb(116,191,102);
    background: linear-gradient(147deg, rgba(116,191,102,1) 16%, rgba(75,138,74,1) 100%); 
}