.listContainer {
    display: flex;
    flex-flow: column;
}

.listItemContainer {
    display: flex;
    flex-flow: row nowrap;
}

.listItem {
    margin: 5px;
    padding: 10px;
    flex-grow: 1;

    background-color: #5D4632;
    text-decoration: none;
    color: #F8F4EF;
}

.listItem:hover {
    background-color: hsl(28, 30%, 32%);
    color: #F8F4EF;
}

.filterAndNewItemButtonContainer {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.newItemButton {
    margin: 5px;
    padding: 10px;

    flex-grow: 1;
    background: rgb(90,149,79);
    background: linear-gradient(147deg, rgba(90,149,79,1) 16%, rgba(57,105,56,1) 100%); 

    white-space: nowrap;
    text-decoration: none;
    color: #F8F4EF;
}

.newItemButton:hover {
    background: rgb(116,191,102);
    background: linear-gradient(147deg, rgba(116,191,102,1) 16%, rgba(75,138,74,1) 100%); 
}

.listFilter {
    margin: 5px;

    background-color: rgba(255, 255, 255, 0.842);
    border: 2px solid #5D46322B;
}
