body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-attachment: fixed;
  background-repeat: no-repeat;
  background: linear-gradient(147deg, rgba(174,138,105,1) 0%, rgba(149,112,79,1) 16%, rgba(74,56,40,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: 100%;
}

.error {
  padding: 20px;
  text-align: center;
  background-color: rgb(104, 17, 35);
  color: white;
}

.loading {
  padding: 20px;
  text-align: center;
  background-color: rgb(118, 155, 207);
  color: black;
}