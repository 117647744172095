.react-select-container {
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: small;
}

.ShoppingItemInputContainer {
  display: grid;
  grid-template-columns: 50px minmax(0, 1fr);
}

.shoppingItemInputNumber {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
}