.App {
  margin: auto;
  text-align: center;
}

.content {
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .content {
    width: 95vw;
  }
}