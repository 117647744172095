.ShoppingItemsList {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.shoppingItemsFilterContainer {
  grid-column: 1 / 4;

  margin: 5px;
}

.shoppingItemsFilter {
  margin: 15px;
  padding: 5px;
  width: 50%;

  background-color: rgba(255, 255, 255, 0.842);
  border: 2px solid #5D4632;
}

.ShoppingItemsListItem {
  margin: 5px;
  padding: 10px;
  background-color: #5D4632;

  text-decoration: none;
  color: #F8F4EF;

}

.ShoppingItemsListItem:hover {
  background-color: hsl(28, 30%, 32%);
  color: #F8F4EF;
}