.shoppingItemDisplay {
    margin: 10px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.082);
    text-align: left;
}

.shoppingItemEditButton {
    float: right;
    width: 20%;
    height: 30px;

    /* color: white;
    background-color: rgba(0, 0, 0, 0.15);
    border-color: rgba(126, 126, 126, 0.75);
    border-style: solid;
    border-radius: 3px; */
}

.shoppingItemTitle {
    font-size: small;
    margin: 0px;
    color: rgb(199, 199, 199);
}

.shoppingItemName {
    font-size: xx-large;
    color: white;
    margin: 0px;
    margin-bottom: 30px;
}

.priceTitle, .locationTitle, .shopTitle {
    color: rgb(199, 199, 199);
}

.priceValue, .locationValue, .shopValue {
    color: white;
}