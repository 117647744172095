.aboutInformation {
    margin: 10px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.082);
    text-align: left;
    color: white;
}

.aboutInformationTitle {
    font-size: xx-large;
    font-weight: normal;
    color: white;
    margin: 0px;
    margin-bottom: 30px;
}

.aboutInformation a {
    color: #F8F4EF;
    text-decoration: none;
    white-space: nowrap;
    text-decoration: underline;
}
